export const NavigationBar = () => {
    return (
        <nav className="navbar">
            <ul className="nav">
                <li className="nav-item"><a href="#">home</a></li>
                <li className="nav-item"><a href="#">new arrival</a></li>
                <li className="nav-item"><a href="#">products</a></li>
                <li className="nav-item"><a href="#">projects</a></li>
                <li className="nav-item"><a href="#">film</a></li>
            </ul>
        </nav>
    );
}