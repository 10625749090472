export const Header = () => {
    const displaySize = window.innerWidth;
    const headerContent = displaySize > 280;
    return (
        <div className={"home-header"}>
            {headerContent && (
                <h1 className={"home-header-h1"}>A beautiful place to recharge your gadgets</h1>
            )}
            {!headerContent && (
                <>
                    <p className={"home-header-p"}>A beautiful place to</p>
                    <p className={"home-header-p"}>recharge your gadgets</p>
                </>
            )
            }
        </div>
    )
}