import {Header} from "./Header";
import {NavigationBar} from "./NavigationBar";
import {Carousel, Container} from "react-bootstrap";
import React from "react";

export const Home = () => {
    const svgWH = 18;
    const searchIconSvgWH = 14;
    const googleDomainUrl = "http://drive.google.com/uc?export=view&id="
    return (
        <>
            <div className={"home-page"}>
                <Header/>
                <div className={"home-container"}>
                    <div className={"home-title-div"}>
                        <img
                            className={"doyo-hoge-icon-img"}
                            src={"https://lh3.googleusercontent.com/fife/AAbDypBieKlT-7_7UzBGQwJMM1f2Fn5NOsruiN5aZl5uSw7ovw0fO_-Dj_GRHDEVzA0SmhuJYwKpkRZmXfdHcjdxWMClW4ClUg3LwEc3yfrpnaOHzrr1apzjXPzN7aRe1GQfS1xUaOtBulLSf_tr6AmMFHa0d-wf3FXkOvCLUGor9mbYLpOnV7yxNSVfOnaJNlEHM65qRbm8xE1N_TZce_-plNw-YtLUiV_rUtDMXat8v23ZQ1qyRIH9bOmC-BZ6BksgjQLLhFjATAstYufAmAf7gNTtm6FMpblcYJJe_ip36ZL3nUbbAPCZk7wQO0HmhfUGQyj4lSm0WVxZxDWgUMOvi1amDh2fQdKZIgyCsdtv6NuF136WBtRvnh0u00uB403l1qCiyop29iNvZBMLthio7PjOD4eLeNfQet6n2lQN5iJxXDZMwzoTpt8jmjCrHt6FyTL3I_-XyDhKdu-e-2GQFMkWoHrXLEHj4eDW62x6pa7yp3sx7h7sl4bi7faey2qmGKQDyoyF6dt5dmYV0CvbhbAe7PIyFBG8N3nRXQJdEVi1DiSfgT3JfbcqEutvtFKwTtegPJWOKbFPQaiMRNFLH2VG7ki_OMmvlfSl1ay2VV3uyd1jl7hnfoOf-ELiVeBSXKpXkM3JVPdBSFbF51iUR8c-VORK9JE3xf4SDTEzcG5poqfit-_0QtWQ2eSJCAAGRzcpveX-YRsNOfMIWdh23c9j8KfbheuH-miNI11iDtwhI0uVTl2IAtdqWcmnm8li0MSXHL1V_2h3-XmVfWA44qIRMGsO3ZMdacIjCpoWdxYul3rFI6RcHMvxnjY6Eg95tWSwsuAR4l4BdgwU06dGuLGwvCACut7sxqqIJqpmclohTTQ6B9Z0W9Tkpnd69ChNF9lnRLkzxFV3ZoJyIG3vQ7A3OUMrpiGPZ7w0eV7efbwILfSAY_E8rBvbD0ug8GCz0chdmId22SxUWyd1qrzFoUb27nX7V_vQmSboEFsfspLFL0COkbHgCIA6_8aKX6MNBze1zRbskhY0tjDdysuoFy0b0ZogSUOeJX3dzA51gddyPR0YUknMwljEPfSEf0A6CoRsG8W2t0I8Bl94NFxL_Ky8HgQI7yJc8vYJKKwK7OprW8rXmA82B3ao_LtkQs11jwkUJejMIkvQfSur2J5purTE86QoseCDBOT4HxhrkLMZ7MmRm9XvmPolrgNYcfV4hvrJ-8mMceq8CJMz3B4qa-Y2H4_h4uK5hEAK_sOJd9sxRlXcHiPhJYO0M1A5L9wMbsDic8QcIEu7s1BsDGxfiM_2fBcXojYehOuwM-nTbp9wnuKt-kjFTMgMzkU5-Zg93qaAfrf_T92MlcWBd-3l7QyQfRIHqika7LWZK926u6DuxEOn-SDTyXHjD8zZn7yCvoneMHOUEXo4ZUhNJOhbGAFd1EKCDOwuT6vlOHHeooS1Wn5fKntEsapD4ORhpFIxe9AEE4sQLCH65IiUUsAadfd-seKz8nLoDBYn5a3oQ9o4wNYIXeWTTjkkJxv7B2NC5IjBi1lhBwksbV0Oip23sZuB0WX6mw=w2880-h1336"}/>
                    </div>
                    <div className={"home-nav-container"}>
                        <NavigationBar/>
                        {/*<div className={"home-nav-container-div"}>*/}
                        {/*    <input className={"home-nav-container-div-input"} placeholder={"search"}/>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width={searchIconSvgWH} height={searchIconSvgWH} fill="currentColor"*/}
                        {/*         className="bi bi-search search-icon-svg" viewBox="0 0 16 16">*/}
                        {/*        <path*/}
                        {/*            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>
                    <Container className={"home-carousel-container"}>
                        <div className={"home-subscribe-icons"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={svgWH} height={svgWH} fill="currentColor"
                                 className="bi bi-youtube" viewBox="0 0 16 16" onClick={() => {
                                window.location.href = "https://www.youtube.com/@kisuke_";
                            }}>
                                <path
                                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={svgWH} height={svgWH} fill="currentColor"
                                 className="bi bi-instagram" viewBox="0 0 16 16" onClick={() => {
                                window.location.href = "https://instagram.com/doyo_design?igshid=YmMyMTA2M2Y=";
                            }}>
                                <path
                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={svgWH} height={svgWH} fill="currentColor"
                                 className="bi bi-twitter" viewBox="0 0 16 16" onClick={() => {
                                window.location.href = "https://twitter.com/doyo_japan";
                            }
                            }
                            >
                                <path
                                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                            </svg>
                        </div>
                        <div className={"home-carousel-container"}>
                            <Carousel className={"home-carousel"}>
                                <Carousel.Item>
                                    <img
                                        className="carousel-img"
                                        src={googleDomainUrl + "1f9cxNG3FP0k6rjSJkSfUa_AVOjB6DHin"}
                                        alt="First slide"
                                    />
                                    <Carousel.Caption>
                                        {/*<h3>my new gear...</h3>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="carousel-img"
                                        src={googleDomainUrl + "1dlnDfgcniJpj1EVkMSK7Rkvwkserr5EF"}
                                        alt="Second slide"
                                    />
                                    <Carousel.Caption>
                                        {/*<h3>Second slide label</h3>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="carousel-img"
                                        src={googleDomainUrl + "1wCKAUsceCJIYBdQkG6lJVWEY8aIg3FXd"}
                                        alt="Second slide"
                                    />
                                    <Carousel.Caption>
                                        {/*<h3>Second slide label</h3>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </Container>
                </div>
                <footer>©DOYO All rights reserved {new Date().getFullYear()}</footer>
            </div>
        </>
    );
}
