import React from 'react';
import './App.css';
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import {Home} from "./pages/home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<Home/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
